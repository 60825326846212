import React, { useCallback, useState, useRef, useEffect } from 'react';
import update from 'immutability-helper';
import { ArcherContainer } from 'react-archer';
import TaskBox from './TaskBox';

const LinksPreview = ({ linkMode, linkType, activities, changeOrder }) => {
    const archerContainerRef = useRef(null);

    const [tasks, setTasks] = useState([]);
    const [isOrderChanging, setIsOrderChanging] = useState(false);

    useEffect(() => {
        console.log('activities', activities)
        setTasks(activities.map((i) => ({ id: i.serverId, text: i.text })));
    }, [activities]);

    const computeRelations = useCallback(
        (index) => {
            const relations = [];
            if (linkMode === 'LINK_BETWEEN') {
                relations.push({
                    targetId: `taskBox-${index + 1}`,
                    sourceAnchor: linkType[0] === 'D' ? 'left' : 'right',
                    targetAnchor: linkType[1] === 'D' ? 'left' : 'right',
                });
            }
            if (linkMode === 'LINK_FIRST_WITH_OTHER' && index > 0) {
                relations.push({
                    targetId: `taskBox-${index}`,
                    sourceAnchor: 'left',
                    targetAnchor: 'left',
                    style: { strokeColor: '#FFFFFF00' }, // hiding make transparent
                });
            }
            // relations only on first activity
            if (linkMode === 'LINK_FIRST_WITH_OTHER' && index === 0) {
                tasks.forEach((act, actIndex) => {
                    relations.push({
                        targetId: `taskBox-${actIndex + 1}`,
                        sourceAnchor: linkType[0] === 'D' ? 'left' : 'right',
                        targetAnchor: linkType[1] === 'D' ? 'left' : 'right',
                    });
                });
            }
            archerContainerRef?.current?.refreshScreen();
            return relations;
        },
        [tasks, linkMode, linkType]
    );

    const moveTask = useCallback((dragIndex, hoverIndex) => {
        setIsOrderChanging(true);
        setTasks((prevTasks) =>
            update(prevTasks, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevTasks[dragIndex]],
                ],
            })
        );
        archerContainerRef?.current?.refreshScreen();
    }, []);

    useEffect(() => {
        if (isOrderChanging) {
            changeOrder(JSON.parse(JSON.stringify(tasks.map((i) => i.id))));
            setIsOrderChanging(false);
        }
    }, [tasks]);

    const renderTask = useCallback(
        (task, index) => {
          const taskRelations = computeRelations(index);
          return (
            <TaskBox
                key={`taskbox-${task.id}`}
                index={index}
                id={task.id}
                text={task.text}
                moveTaskBox={moveTask}
                relations={taskRelations}
            />
        )},
        [tasks]
    );

    return (
        <ArcherContainer
            style={{ width: 'fit-content', height: 'fit-content', overflow: 'visible' }}
            ref={archerContainerRef}
            lineStyle="curve"
            strokeColor="blue"
            strokeWidth={1}
            endShape={{
                arrow: {
                    arrowLength: 7,
                    arrowThickness: 5,
                },
                circle: {
                    radius: 2,
                    fillColor: '#f00',
                    strokeColor: '#0ff',
                    strokeWidth: 1,
                },
            }}
            svgContainerStyle={{
              overflow: 'overlay'
            }}
        >
            <div className="flex justify-center flex-col">{tasks.length > 0 && tasks.map((task, index) => renderTask(task, index))}</div>
        </ArcherContainer>
    );
};

export default LinksPreview;
