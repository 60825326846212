import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import ActivityService from '../../../services/activity.service';
import { refreshPlanningSelected } from '../../../redux/thunks/planning-slice.thunk';


const MultipleActivityDuplication = ({ activitiesId }) => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleDuplicateMultipleActivity = async () => {
        setLoading(true);
        await ActivityService.duplicateMultipleActivity(activitiesId)
        setLoading(false);
        dispatch(refreshPlanningSelected(true))
    }

    return (
        <Button type="primary" className='mr-2' loading={loading} onClick={() => handleDuplicateMultipleActivity()}>
            {t('gantt.duplicate_multiple')}
        </Button>
    );
};

MultipleActivityDuplication.propTypes = {
    activitiesId: PropTypes.array.isRequired,
};

export default MultipleActivityDuplication;
