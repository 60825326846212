import React, { useRef } from 'react';
import styled from 'styled-components';
import { ArcherElement } from 'react-archer';
import { useDrag, useDrop } from 'react-dnd';

const TaskBoxContainer = styled.div`
    width: 50px;
    height: 25px;
    font-size: 12px;
    background: #828282;
    padding-left: 4px;
    margin-bottom: 8px;
    text-align: center;
    color: white;
    border: solid #000 1px;
    p {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;
const type = 'DraggableBodyRow';

const TaskBox = ({ id, text, index, moveTaskBox, relations }) => {
    const style = {
        cursor: 'move',
    };
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: type,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveTaskBox(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.

            // eslint-disable-next-line
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type,
        item: () => ({ id, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0.5 : 1;
    drag(drop(ref));
    return (
        <TaskBoxContainer style={{ ...style, opacity, marginLeft: `${index * 25}px` }} data-handler-id={handlerId} ref={ref}>
            <ArcherElement id={`taskBox-${index}`} relations={relations}>
                <p title={text}>{text}</p>
            </ArcherElement>
        </TaskBoxContainer>
    );
};

export default TaskBox;
